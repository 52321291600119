import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import logo from 'src/images/logoNav.png';
import { navLinks } from '../../utils/navMenu';
import { SideIcons } from '../../utils/navMenu';

import { Link } from '../../utils/general';

import { useMedia } from 'react-use';
import Header from "../../../ui-treact/components/headers/light"; 

export let NavLink = tw(Link)`
text-xl my-0 lg:text-sm lg:mx-6 lg:my-0
 tracking-wide transition duration-300 
pb-1 border-b-2 border-transparent  hocus:text-brand-900 hocus:no-underline	
`;
export const PrimaryLink = tw(NavLink)`
px-8 py-3  bg-white  text-black font-bold
hocus:bg-white  hocus:text-black  text-sm
border-b-0 hocus:shadow-lg block
`;
const mainHeader = ({ bgColor = 'bg-white' }) => {
  const [showMenu, setShowMenu] = useState('hidden');
  const Container = styled.div`
    ${tw`flex items-center justify-between py-0 md:space-x-10 py-4`}
    ${bgColor == 'bg-white' && tw`border-b-0 border-gray-100`}
  `;
  const isMd = useMedia('(min-width: 1240px)');




  const StyledDiv = styled.div`

  .more-btn{
    ${tw`flex items-center justify-center pb-1 text-xs font-semibold tracking-wide transition duration-300 border-b-2 border-transparent lg:text-sm text-secondary-900 hover:border-black hocus:text-secondary-900 hover:no-underline`}
  }
  .drop-down {
    display: none;
}
&:hover{

  .drop-down {
    display: block;
}

}
.more-btn:hover {
    display: flex;
    color: balck;
}
`


  return (
    <div tw="relative  z-50 bg-white">
      <div tw="max-w-7xl mx-auto px-4 sm:px-6">
        <Container>
          <div tw="flex justify-start flex-1 flex-row">
            <Link to="/" tw="flex justify-center items-center flex-row hocus:text-white hover:no-underline">
              <img tw="h-16 w-auto" src={logo} alt="" />
              <div tw="text-secondary-900 font-semibold">SICKMKTG</div>
            </Link>
          </div> 
          <div tw="-mr-2 -my-2 md:hidden">
            <button 
              onClick={() => {
                setShowMenu('visible');
              }}
              type="button"
              tw="bg-secondary-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-secondary-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span tw="sr-only">Open menu</span>
              <svg
                tw="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <nav tw="hidden md:flex space-x-4">
            {!isMd
              ? navLinks.slice(0,2).map((e) => (
                e.isAvailbelOnTablet ? (
                  <Link
                    to={e.url}
                    tw="px-1 text-xs  lg:text-sm
              font-semibold tracking-wide transition duration-300 text-secondary-900
              pb-1 border-b-2 border-transparent hover:border-secondary-900 hocus:text-secondary-900 hover:no-underline flex justify-center items-center"
                  >
                    {e.text}
                  </Link>
                ) : null
                ))
              : navLinks.slice(0,5).map((e) =>
                  <Link
                  to={e.url}
                  tw="px-1 text-xs lg:text-sm
              font-semibold tracking-wide transition duration-300 text-secondary-900
              pb-1 border-b-2 border-transparent hover:border-secondary-900 hocus:text-secondary-900 hover:no-underline flex justify-center items-center"
                >
                  {e.text}
                </Link>
                )}

            <StyledDiv className="relative">
                                <button className="more-btn">
                                    More
                                    <svg class="ml-1 h-5 w-5 text-secondary-900 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#70b9ff" aria-hidden="true">
                          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                                </button>
                                
                              <div className="drop-down" tw="absolute z-10 left-auto transform -translate-x-16 pt-3 px-2 sm:px-0 w-40 lg:w-48">
                                      
                                    <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black divide-gray-100">
                              <div tw="pt-2 pb-2 px-5">
                                <div tw="flex  flex-col mt-2">
                          {!isMd
                          ? navLinks.slice(2,navLinks.length).map((e) =>
                          <Link
                          to={e.url}
                          tw="text-xs lg:text-sm
                      font-semibold tracking-wide transition duration-300 text-secondary-900
                      pb-1 border-b-2 border-transparent hover:border-secondary-500 hocus:text-secondary-900 hover:no-underline flex justify-center items-center"
                        >
                          {e.text}
                        </Link>
                        )
                          : navLinks.slice(5,navLinks.length).map((e) =>
                              <Link
                              to={e.url}
                              tw="text-xs lg:text-sm
                          font-semibold tracking-wide transition duration-300 text-secondary-900
                          pb-1 border-b-2 border-transparent hover:border-secondary-900 hocus:text-secondary-900 hover:no-underline flex justify-center items-center"
                            >
                              {e.text}
                            </Link>
                            )}
                                </div>
                              </div>
                            </div>
                              </div>
            </StyledDiv>
            <Link
                  to="/pages/contact-us/"
                  tw="text-xs lg:text-sm
              font-semibold tracking-wide transition duration-300 text-secondary-900 hocus:text-secondary-900 hover:no-underline flex justify-center items-center -mt-2"
                >
                  Contact Us
            </Link>
              {/* <PrimaryLink css={tw`rounded-full`} href="/#">
                Get Started
              </PrimaryLink> */}
          </nav>
        </Container>
      </div>

      <div
        tw="absolute top-0 inset-x-0 p-2 transition transform origin-top-right"
        className={showMenu}
      >
        <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-secondary-900 divide-y-2 divide-gray-100">
          <div tw="pt-5 pb-6 px-5 bg-white">
            <div tw="flex items-center justify-between ">
              <div tw="flex justify-center items-center flex-row hocus:text-white hover:no-underline">
                <img 
                  tw="h-16 w-auto"
                  src={logo}
                  alt="Workflow"
                />
                <div tw="text-secondary-900 font-semibold">SICKMKTG</div>
              </div>
              <div tw="-mr-2">
                <button
                  onClick={() => {
                    setShowMenu('hidden');
                  }}
                  type="button"
                  tw="bg-secondary-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-secondary-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span tw="sr-only">Close menu</span>
                  <svg
                    tw="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div tw="flex  flex-col mt-6">
              {navLinks.map((e) => (
                <Link
                  to={e.url}
                  tw="text-sm my-2 lg:text-sm lg:mx-3 lg:my-0
                font-semibold tracking-wide transition duration-300 text-secondary-900
                pb-1 border-b-0 border-transparent hover:border-secondary-900 hocus:text-secondary-900 hover:no-underline"
                >
                  {e.text}
                </Link>
              ))}
            </div>
          </div>
          {/* <div tw="py-6 px-5 space-y-6 bg-secondary-900">
            <div>
              <a
                href="#"
                tw="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-bold text-black hover:text-black bg-white hover:no-underline"
              >
                Get Started
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default mainHeader;
