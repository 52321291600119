import React, { useState, useContext } from 'react';
import { NavLink, NavLinks, PrimaryLink } from 'src/ui-treact/components/headers/light';

const MenuContext = React.createContext(0);
MenuContext.displayName = 'MenuContext';
const { Provider } = MenuContext;

export function MenuContextProvider({ children }) {
  const [menuShowed, setMenuShowed] = useState(false);

  return <Provider value={[menuShowed, setMenuShowed]}>{children}</Provider>;
}

export function useMenuContext() {
  const [menuShowed, setMenuShowed] = useContext(MenuContext);
  const navLinks=[ 
    // {
    //   id: 1,
    //   text: "About ProLon",
    //   url: "#", 
    //   isMain: false,
    // },
    // {
    //   id: 2,
    //   text: "Fasting Mimicking Diet",
    //   url: "https://prolonfast.com/pages/pages/fasting-mimicking-diet",
    //   isMain: false,
    // },
    // {
    //   id: 3,
    //   text: "Contact",
    //   url: "https://prolonfast.com/pages/contact-us",
    //   isMain: false, 
    // }, 
    {
      id: 4,
      text: "ORDER NOW + FREE SHIPPING",
      url: "/products/buy-prolon", 
      isMain: true, 
    }
  ];
   
  let normalNavLinks = [];
  let primaryNavLinks = [];

  {
    navLinks && navLinks.forEach((link) => {
        if (!link.isMain) {
          normalNavLinks.push(<NavLink to={link.url}>{link.text}</NavLink>);
        } else { 
          primaryNavLinks.push(
            <PrimaryLink to={link.url}>{link.text}</PrimaryLink>
          );
        } 
      });
  }
 
  const Links = [
    <NavLinks key={1}>{normalNavLinks}</NavLinks>,
    <NavLinks key={2}>{primaryNavLinks}</NavLinks>,
  ]; 

  return {   
    menuShowed,Links,primaryNavLinks,
    setMenuShowed,
  };
} 
 