import "@fontsource/nunito-sans";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/700.css";
import loadable from '@loadable/component';
import { graphql, useStaticQuery } from 'gatsby';
import { Provider } from 'jotai';
import { observer } from "mobx-react";
import React from 'react';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import { Box, Flex } from 'rebass';
import { Styled, ThemeProvider } from 'theme-ui';
import { GlobalStyles } from 'twin.macro';
import MainHeader from '../custom-sections/Header/main-header';
import theme from '../gatsby-plugin-theme-ui/index';

import 'src/prolon-theme/style/global.css';
import 'src/ui-treact/global.css';
import './reset.css';  

import styled from 'styled-components';
import tw from 'twin.macro'
import './highlighter.scss'
 
import {useMenuContext} from '../../theme/.components/Menu/context';
  
import Header from "../../ui-treact/components/headers/light"; 
// import SnowStorm from 'react-snowstorm';
import SnowUp from "../utils/SnowUp";

// const SlideCartLoader = loadable(() => import('src/slide-cart'));  
const Footer = loadable(() => import('../../../src/theme/custom-sections/Agency/HomePage/AgencyFooter.js')); 

const initializeReactGA = (googleAnalyticsId) => {
  ReactGA.initialize(googleAnalyticsId);
  if (typeof window !== `undefined`) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  } 
}; 
 
const Layout = ({header=true,children }) => {
  
 
  const data = useStaticQuery(
    graphql` 
      query _LayoutStaticQuery {
        site {
          siteMetadata {
            gatsbyStorefrontConfig {
              googleAnalyticsId
            }
          }
        }
      }
    `
  );



  

  const { googleAnalyticsId } = data.site.siteMetadata.gatsbyStorefrontConfig;

  initializeReactGA(googleAnalyticsId);

  return (
    <ThemeProvider theme={theme}>
      <Provider>
      <GlobalStyles /> 
      <Styled.root>
        <LayoutComponents children={children} header={header} />
      </Styled.root>
      </Provider>
    </ThemeProvider>
  );
};


const StyledHeader = styled(Header)`
   ${tw`w-full bg-white  md:py-6`}
  @media(max-width:767px){
    height: 79px;
    display: flex;
  } 
`; 
const LayoutComponents = ({ header,children }) => {
  const {Links,primaryNavLinks} = useMenuContext();

  return (
    <Box
      bg="background"
      sx={{
        width: '100%',
      }}
    >
      <Helmet>
        <html lang="en" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
      </Helmet>
   
      <Flex className="main-container" flexDirection="column" style={{ minHeight: '100vh' }}>
      {typeof window !== `undefined` &&
        // <SnowStorm style={{ zIndex: '999999999999 !important' }}
        //   snowColor="#01B8FC" 
        //   vMaxY="3"
        //   // animationInterval="500"
        //   // snowStick="false"
        //   />
        <SnowUp />
      }
       {header && <MainHeader />} 
       {/* {window.location.pathname !=='/' && <AnnouncementBar /> }  */}
        <Box 
          as="main"
          flex="1"
          width={1} 
          // style={{ maxWidth: 1200, height: '100%' }}
          mx="auto"  
          className="main-wrapper" 
        >
          {children}
        </Box>  
        {/* <SlideCartLoader /> */}
        <Footer /> 
      </Flex>
    </Box>
  );
};

export default observer(Layout);  
