import React from 'react'
import Particles from 'react-tsparticles'

export default function SnowUp() {
    return (
        <Particles
      options={{
        backgroundMode: {
          enable: true,
          zIndex: 100
        },
        // background: {
        //   color: "#000"
        // },
        fpsLimit: 28,
        interactivity: {
          detectsOn: "window",
          events: {
            onClick: { enable: false, mode: "repulse" },
            onHover: {
              enable: false,
              mode: "bubble",
              parallax: { enable: false, force: 2, smooth: 10 }
            },
            resize: false
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 0.3,
              opacity: 1,
              size: 4,
              speed: 3
            },
            grab: { distance: 400, line_linked: { opacity: 0.5 } },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 },
            repulse: { distance: 200, duration: 0.4 }
          }
        },
        particles: {
          color: { value: "#ff9623" },
          links: {
            color: "#ffffff",
            distance: 500,
            enable: false,
            opacity: 0.4,
            width: 2
          },
          move: {
            attract: { enable: false, rotateX: 600, rotateY: 1200 },
            direction: "top",
            enable: true,
            outMode: "out",
            random: false,
            size: true,
            speed: 1,
            straight: false
          },
          number: { density: { enable: true, area: 800 }, value: 100 },
          opacity: {
            random: true,
            value: 0.5
          },
          shape: {
            type: "circle"
          },
          size: {
            random: true,
            value: 4
          }
        },
        detectRetina: true

    //     fps_limit: 28,
	//     particles: {
	//         collisions: {
	//             enable: false
	//         },
	//         number: {
	//             value: 200,
	//             density: {
	//                 enable: false
	//             }
	//         },
	//         line_linked: {
	//             enable: true,
	//             distance: 30,
	//             opacity: 0.4
	//         },
	//         move: {
	//             speed: 1
	//         },
	//         opacity: {
	//             anim: {
	//                 enable: true,
	//                 opacity_min: 0.05,
	//                 speed: 1,
	//                 sync: false
	//             },
	//             value: 0.4
	//         }
	//     },
	//     polygon: {
	//         enable: true,
	//         scale: 0.5,
	//         type: "inline",
	//         move: {
	//             "radius": 10
	//         },
	//         url: "/small-deer.2a0425af.svg",
	//         inline: {
	//             "arrangement": "equidistant"
	//         },
	//         draw: {
	//             enable: true,
	//             stroke: {
	//                 color: "rgba(255, 255, 255, .2)"
	//             }
	//         }
	//     },
	//     retina_detect: false,
	//     interactivity: {
	//         events: {
	//             onhover: {
	//                 enable: true,
	//                 mode: "bubble"
	//             }
	//         },
	//         modes: {
	//             bubble: {
	//                 size: 6,
	//                 distance: 40
	//             }
	//         }

    //   }
    }
}
    />
    )
}
