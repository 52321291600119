import React from 'react';
import loadable from '@loadable/component';
import ErrorBoundary from '../ErrorBoundary';
// import { showMainHeader } from '../../store';
// import { useAtom } from 'jotai';
import { sortBy } from 'lodash';

let ComponentLoader = null;
const windowGlobal = typeof window !== 'undefined' && window;

// const  HeroComponentOffer = loadable(() => import(`../../custom-sections/custom-sections-product/ImageSection/OnlyImage.js`));
// const  TextBannerOffer = loadable(() => import(`../../custom-sections/custom-sections-product/TextSection/TextBanner.js`));
// const  BuyCardsList = loadable(() => import(`../../custom-sections/custom-sections-product/CardSection/BuyCardsList.js`));
// const  PlanSectionList = loadable(() => import(`../../custom-sections/custom-sections-product/PlanSection/PlanSection.js`));
// const  HeroBannerDeals = loadable(() => import(`../../custom-sections/custom-sections-product/TextSection/HeroBanner.js`));
// const  BuyProlon = loadable(() => import(`../../custom-sections/BuyProlon/BuyProlon.js`));
// const  PromoGridHome = loadable(() => import(`../../custom-sections/HomePage/PromoGrid/Hero.js`));
// const  TextSectionHome = loadable(() => import(`../../custom-sections/HomePage/TextSection/TextSection.js`));
// const  WorkSection = loadable(() => import(`../../custom-sections/HomePage/WorkSection/WorkSection.js`));
// const  DietProlon = loadable(() => import(`../../custom-sections/HomePage/DietSection/FastingMimicking.js`));
// const  BenifitProlon = loadable(() => import(`../../custom-sections/HomePage/BenifitSection/BenifitSection.js`));
// const  TextOnImage = loadable(() => import(`../../custom-sections/BuyProlon/ImageOnText`));
// const  TextOnlyLpw = loadable(() => import(`../../custom-sections/BuyProlon/TextOnly`));
// const  TextCardList = loadable(() => import(`../../custom-sections/BuyProlon/TextCardList`));
// const  Testimonials = loadable(() => import(`../../custom-sections/HomePage/Testimonials/Testimonials`));
// const  PromoGridHomeLeft = loadable(() => import(`../../custom-sections/HomePage/PromoGrid/Hero-Left.js`));
// const  ScrollImage = loadable(() => import(`../../custom-sections/HomePage/PromoGrid/ScrollImage`));

// // Marketing DNA 
// const  HeroMKT = loadable(() => import(`../../custom-sections/MarketingDNA/HomePage/HeroMarketing`));
// const  NewsLetterMKT = loadable(() => import(`../../custom-sections/MarketingDNA/HomePage/NewsLetter`));
// const  GettingServiceMKT = loadable(() => import(`../../custom-sections/MarketingDNA/HomePage/Services`));
// const  SideServiceMKT = loadable(() => import(`../../custom-sections/MarketingDNA/HomePage/ServicesSide`));
// const  StatsMKT = loadable(() => import(`../../custom-sections/MarketingDNA/HomePage/Stats`));
// const  CTAMKT = loadable(() => import(`../../custom-sections/MarketingDNA/HomePage/CTA`));
// const  ImageTextMKT = loadable(() => import(`../../custom-sections/MarketingDNA/HomePage/ScrollImage`));
// const  MetricsMKT = loadable(() => import(`../../custom-sections/MarketingDNA/HomePage/Metrics`));
// const  AboutHeroMKT = loadable(() => import(`../../custom-sections/MarketingDNA/AboutUs/AboutHero`));
// const  ContactHeroMKT = loadable(() => import(`../../custom-sections/MarketingDNA/ContactUs/ContactHero`));

// Sick Marketing
const  AgencyHero = loadable(() => import(`../../custom-sections/Agency/HomePage/AgencyHero.js`));
const  AgencyFeature = loadable(() => import(`../../custom-sections/Agency/HomePage/AgencyFeature.js`));
const  AgencyServices = loadable(() => import(`../../custom-sections/Agency/HomePage/AgencyServices.js`));
const  AgencyTeam = loadable(() => import(`../../custom-sections/Agency/HomePage/AgencyTeam.js`));
const  AgencyPorfolio = loadable(() => import(`../../custom-sections/Agency/HomePage/AgencyPorfolio.js`));
const  AgencyTestimonial = loadable(() => import(`../../custom-sections/Agency/HomePage/AgencyTestimonial.js`));
const  AgencyFAQ = loadable(() => import(`../../custom-sections/Agency/HomePage/AgencyFAQ.js`));
const  AgencyBlog = loadable(() => import(`../../custom-sections/Agency/HomePage/AgencyBlog.js`));
const  AgencyContactUs = loadable(() => import(`../../custom-sections/Agency/HomePage/AgencyContactUs.js`));

const PageLoader = ({ productData, pageData, page }) => {
  let sections = pageData?.sections;
  let jsonData = pageData?.schema_jsons;
  sections = sortBy(sections, 'priority');

  let variants = productData?.data?.allShopifyProduct?.edges[0]?.node?.variants;

  return (
    <>
      {sections && sections.length > 0 ? (
        sections.map((s) => {

          let node = s;
          let data = null;
          if (jsonData && jsonData.length > 0) {
            data = jsonData.filter((e) => {
              return e.section == s.id;
            });
            if (data && data.length > 0) {
              data = data[0];
            }
          }

          switch (node.type) {
            // case 'image-section': return <HeroComponentOffer page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'text-banner': return <TextBannerOffer page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'buy-card-section': return <BuyCardsList page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'plan-section': return <PlanSectionList page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'hero-deals': return <HeroBannerDeals page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'buy-prolon': return <BuyProlon page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'promo-grid': return <PromoGridHome page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'text-section-home': return <TextSectionHome page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'work-section': return <WorkSection page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'benifit_section': return <BenifitProlon page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'diet_section': return <DietProlon page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'text-on-image': return <TextOnImage page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'text-only-lpw': return <TextOnlyLpw page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'text-card-list': return <TextCardList page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'testimonial': return <Testimonials page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'promo-grid-left': return <PromoGridHomeLeft page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'image-scroll': return <ScrollImage page={page} component={node.path} data={data} productVariants={variants}/>
            
            // // Marketing DNA 
            // case 'hero-marketing': return <HeroMKT page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'newsletter': return <NewsLetterMKT page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'getting-services': return <GettingServiceMKT page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'side-services': return <SideServiceMKT page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'stats': return <StatsMKT page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'cta': return <CTAMKT page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'scrollImage-mktdna': return <ImageTextMKT page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'metrics': return <MetricsMKT page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'about-hero': return <AboutHeroMKT page={page} component={node.path} data={data} productVariants={variants}/>
            // case 'contact-hero': return <ContactHeroMKT page={page} component={node.path} data={data} productVariants={variants}/>

            // SickMarketing 
            case 'agency-hero': return <AgencyHero page={page} component={node.path} data={data} productVariants={variants}/>
            case 'agency-feature': return <AgencyFeature page={page} component={node.path} data={data} productVariants={variants}/>
            case 'agency-services': return <AgencyServices page={page} component={node.path} data={data} productVariants={variants}/>
            case 'agency-team': return <AgencyTeam page={page} component={node.path} data={data} productVariants={variants}/>
            case 'agency-porfolio': return <AgencyPorfolio page={page} component={node.path} data={data} productVariants={variants}/>
            case 'agency-testimonial': return <AgencyTestimonial page={page} component={node.path} data={data} productVariants={variants}/>
            case 'agency-faq': return <AgencyFAQ page={page} component={node.path} data={data} productVariants={variants}/>
            case 'agency-blog': return <AgencyBlog page={page} component={node.path} data={data} productVariants={variants}/>
            case 'agency-contact-us': return <AgencyContactUs page={page} component={node.path} data={data} productVariants={variants}/>
            

            default:
              if (windowGlobal) {
                ComponentLoader = loadable((props) => {
                  return import(`../../custom-sections/${props.component}`);
                });
              } 
              break;
          }
          // if (node.type == 'image-section') {
          //   return (
          //     <HeroComponent page={page} component={node.path} data={data} />
          //   );
          // } 
 
          // console.log('data :>> ', data, node.type);
          if (node.type)
            return (
              <ErrorBoundary key={node.id}>
                {ComponentLoader && (
                  <ComponentLoader
                    page={page}
                    component={node.path}
                    productVariants={variants}
                    data={data}
                  />
                )}
              </ErrorBoundary>
            );
          else return 'Component Not Found';
        })
      ) : (
        <div dangerouslySetInnerHTML={{ __html: page.body }}></div>
      )}
    </>
  );
};

export default PageLoader;
